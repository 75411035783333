import axios from 'axios'
const base_url = 'https://hammel.mychatassist.fr'

export const createQt = async () => {

  try {
    return await axios({
      method: 'post',
      url: `${base_url}/chat`
    })
  } catch (error) {
    console.error(error)
  }

}

export const postQuestions = async (idQt, qt) => {

  try {
    return await axios({
      method: 'post',
      url: `${base_url}/chat/tuned/${idQt}/message`,
      data: qt
    })
  } catch (error) {
    console.error(error)
  }

}

export const getForm = async (idQt) => {

  try {
    return await axios({
      method: 'get',
      url: `${base_url}/chat/${idQt}`
    })
  } catch (error) {
    console.error(error)
  }

}
