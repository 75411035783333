import React from 'react';

const MessageParser = ({ children, actions }) => {

  const parse = async (message) => {

    actions.next(null, message);
  };

  return (
      <div>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            parse,
            actions,
          });
        })}
      </div>
  );
};

export default MessageParser;
