import React from 'react';
import {createQt, postQuestions} from "../api/service";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const states = children.props.children.props.state;

  const next = async (id, message) => {

      const icon = document.querySelector('.react-chatbot-kit-chat-btn-send-icon');
      const btn = document.querySelector('.react-chatbot-kit-chat-btn-send');
      const loading_point = document.createElement('span');
      const next_point = document.createElement('span');
      loading_point.className = 'loading-points';
      loading_point.append(next_point);
      icon.style.display = 'none';
      btn.append(loading_point);

      let idQt = states.idQt;
      if (!idQt) {
          const pq = await createQt();
          idQt = pq.data.id;
          setState((prev) => {
              return {...prev, ...{idQt: pq.data.id}}
          });
      }

      let question = {};
      let finalValue = null

      console.log(states)

      question.question = '';
      if(idQt) {
          const pqapi = await postQuestions(idQt, {"message": message});
          question.question = pqapi.data.output;
      }

      icon.style.display = 'block';
      loading_point.remove();

      const allDivs = document.querySelectorAll('.react-chatbot-kit-chat-bot-message');
      const lastElement = allDivs[allDivs.length - 1];
      setTimeout(() => {
          lastElement.scrollIntoView()
      }, 3000)

      if (question && question.final) {
          finalValue = question.value
          setState(prevState => ({
              ...prevState,
              skinType: finalValue
          }))
          id = question.next//states.selfie ? question.next : 28
      }

      message = createChatBotMessage(
          question.question,
          {
              payload: {uid: id, id: question.id},
              loading: true,
              terminateLoading: true,
              withAvatar: true,
              delay: question.delay || null,
              widget: question.widget || null,
          }
      );
      addMessageToState(message)
  }

  const createBotMessage = (msg, options= {}) => {
    const message = createChatBotMessage(
        msg,
        options
    );
    addMessageToState(message)
  };

  const addMessageToState = (message) => {
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  }

  return (
      <div>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            actions: {next,  createBotMessage},
          });
        })}
      </div>
  );
};

export default ActionProvider;
