import './App.css';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import React, {  useEffect, useState } from 'react';

function App() {
  return (
    <div>
      <Chatbot
        config={config}
        headerText='Hammel C.S.'
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        placeholderText='Message'
        validator={(msg) =>  msg.length > 0}
        />
    </div>
  );
}

export default App;
