import { createChatBotMessage } from 'react-chatbot-kit';
import MyAvatar from "../myAvatar/myAvatar";

const config = {
  botName: 'Hammel C.S.',
  initialMessages: [createChatBotMessage(`Salut ! Je suis L' intelligence artificielle de Raymond HAMMEL conçue pour répondre à vos questions et résoudre vos problèmes. Comment puis-je vous assister ?`, {})],
  customComponents: {
    botAvatar: (props) => <MyAvatar {...props} />
  },
  state: {},
  idQt: null
};

export default config;
